import React from 'react'
import { MDXTag } from '@mdx-js/tag'


import DefaultLayout from "/Users/angel/conceptsauce/syrup/packages/site/src/templates/mdx.js"

export default class MDXContent extends React.Component {
  constructor(props) {
    super(props)
    this.layout = DefaultLayout
  }
  render() {
    const { components, ...props } = this.props

    return <MDXTag
             name="wrapper"
             Layout={this.layout} layoutProps={props}
             components={components}>

<MDXTag name="h2" components={components}>{`Syrup`}</MDXTag>
<MDXTag name="p" components={components}><MDXTag name="a" components={components} parentName="p" props={{"href":"https://conceptsauce.io"}}>{`ConceptSauce`}</MDXTag>{` survey data analysis library.
Survey data is available to clients from our public API endpoint as a JSON object which is necessarily both large and complex. Syrup is designed to aid navigation and analysis of this data in real time during an active survey.`}</MDXTag>
<MDXTag name="h3" components={components}>{`Getting started`}</MDXTag>
<MDXTag name="p" components={components}>{`This site has many live code samples that can be run and edited right within the doc pages. Get a feel for the capabilities in the `}<MDXTag name="a" components={components} parentName="p" props={{"href":"/sandbox"}}>{`sandbox`}</MDXTag>{` and browse the `}<MDXTag name="a" components={components} parentName="p" props={{"href":"/cookbook"}}>{`cookbook`}</MDXTag>{` for pre-built solutions to common client needs. We are constantly adding to the cookbook so please ask if you don't see what you need.`}</MDXTag>
           </MDXTag>
  }
}

export const _frontmatter = {};

  